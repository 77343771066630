export default function useIsMobile() {
  // Create a shared state using useState
  const isMobile = useState('isMobile', () => false);
  const isMobileInitialized = ref(false);

  const updateIsMobile = () => {
    if (typeof window !== 'undefined') {
      isMobile.value = window.innerWidth <= 1024;
    }
  };

  onMounted(() => {
    updateIsMobile();
    isMobileInitialized.value = true;
    window.addEventListener('resize', updateIsMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
  });

  return { isMobile, isMobileInitialized };
}